
  <div class="login">

    <main class="login__main">

      <div class="login__left">
        <div class="login__form">
          <div class="login__logo">
            <img *ngIf="logo" class="tp-logo" src="../../assets/logos/{{logo}}" />
          </div>
          <div class="login__intro">
            Empower us to showcase your
            expertise to schools seeking educators like
            you.
          </div>
          
          <div class="btn-group" *ngIf="!loading">
            <button *ngIf="doEducatorLoginFlow" class="login__login-button" type="button" [disabled] (click)="doSegIdentityLogin()">Sign in to {{appName}}</button>
            <button *ngIf="!doEducatorLoginFlow" class="login__login-button" type="button" [disabled]>Sign in to {{appName}}</button>
          </div>
          <div *ngIf="loading">
            <app-loading-spinner></app-loading-spinner>
          </div>

        </div>
      </div>
      
      <div class="login__right">
        <div class="login__graphic"></div>
      </div>

    </main>
      
      
</div>  
